<template>
  <div id="open-enrollment-subview">
    <!-- Side Progress Bar -->
    <!-- <nav
      aria-label="Progress"
      class="hidden lg:flex my-4 fixed ml-4 xl:ml-16 2xl:ml-32 w-56 left-0 bottom-12"
    >
      <ol role="list" class="overflow-hidden flex flex-col">
        <li
          v-for="(step, stepIdx) in timeline"
          :key="step.name"
          :class="[stepIdx !== timeline.length - 1 ? 'pb-6' : '', 'relative']"
        >
          <template>
            <div
              v-if="stepIdx !== timeline.length - 1"
              class="absolute left-4 top-4 -ml-0.5 mt-0.5 h-12 w-1 bg-primary-800"
              aria-hidden="true"
            />
            <a
              :href="step.href"
              class="group relative flex items-center"
              v-smooth-scroll
            >
              <span class="flex h-9 items-center">
                <img
                  v-if="
                    (current == step.name && current != '') ||
                      order.indexOf(step.name) < order.indexOf(current) ||
                      step.name == 'Open Enrollment'
                  "
                  class="w-8"
                  src="@/assets/images/check.svg"
                />
                <span
                  v-else
                  class="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-4 border-primary-800 bg-white"
                >
                </span>
              </span>
              <span class="ml-4 flex min-w-0 flex-col">
                <span class="text-sm font-bold">{{ step.name }}</span>
              </span>
            </a>
          </template>
        </li>
      </ol>
    </nav> -->
    <!-- <div class="fixed bottom-8 left-8">
      <div>{{ windowTop }}</div>
      <div>Education {{ positionEducation }}</div>
      <div>Gather {{ positionGather }}</div>
      <div>Upload {{ positionUpload }}</div>
    </div> -->
    <div class="hidden md:flex flex-col">
      <!-- First - Open Enrollment -->
      <div class=" relative flex items-start w-full mb-20">
        <div class="flex flex-col items-center border-primary-800 w-1/2">
          <!-- Left Top -->
          <div class=" flex min-w-0 flex-col items-center w-full">
            <!-- Why -->
            <div
              class="flex min-w-0 flex-col pb-16 border-r-4 border-white  w-full pl-16 pt-8"
            >
              <div id="enrollment" class="text-xl font-bold mb-2 text-left">
                Why Should I Offer Benefits?
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80 mb-4">
                Funds elected into benefit accounts are pre-tax dollars and have
                a return investment of 7.6%. The more employees enroll, the
                larger your return.
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80 mb-4">
                Most qualified job seekers look for companies who can offer them
                health and wellbeing security on top of financial security.
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80">
                With less financial stress, employees enrolled in benefits are
                more likely to stay with your company and perform at their best.
              </div>
            </div>
          </div>
        </div>
        <div class=" w-1/2 flex flex-col items-center py-4">
          <!-- Right top -->
          <div
            class="flex min-w-0 flex-col bg-primary-800 p-4 rounded md:w-64 lg:w-80 xl:w-96 mb-16 ml-8"
          >
            <div class="text-xl font-bold text-white mb-2">Open Enrollment</div>
            <div class="text-sm text-white">
              A smooth and easy open enrollment starts with your employees
              understanding their options and you understanding the steps in the
              process timeline. It is in your best interest to dedicate adequate
              time to complete each step to its fullest.
            </div>
          </div>
        </div>
      </div>
      <!-- Second - Employee Education -->

      <div ref="education" class="relative flex items-start w-full timeline">
        <div class="flex flex-col border-primary-800 w-1/2">
          <!-- Left Top -->
          <div class=" flex min-w-0 flex-col w-full">
            <!-- Employee Education -->
            <div class="flex justify-end left-6 w-full relative">
              <span
                class="relative left-1 top-1 z-10 my-4  h-1 w-1/5  bg-gray-250"
              >
              </span>
              <span
                class="z-10 h-12 w-12 rounded-full border-8 border-primary-800 bg-white"
              >
              </span>
            </div>
            <div class="flex min-w-0 flex-col w-full pl-16 relative bottom-16">
              <div id="education" class="text-lg font-bold mb-2">
                Employee Education
              </div>
              <div class="text-xs text-primary-800 font-bold mb-2">
                months leading to open enrollment
              </div>

              <div class="text-sm text-gray-750 w-72 lg:w-80">
                Providing employees resources to help them understand benefits
                offered can greatly increase participation rates.
              </div>
            </div>
          </div>
        </div>
        <div class=" w-1/2 flex flex-col items-center py-4">
          <!-- Right top -->
          <img
            class="w-96 ml-8 text-center relative bottom-36 right-12"
            src="@/assets/images/employeeEducation.svg"
          />
        </div>
      </div>
      <!-- Materials -->
      <div class="flex min-w-0 flex-col w-full md:timeline relative bottom-36">
        <div class="text-lg font-bold mb-2 pl-16 text-primary-800">
          Materials
        </div>
        <div class=" w-full flex justify-center ">
          <div
            class="grid grid-cols-1 gap-2 sm:grid-cols-2 w-full mx-12  border-2 border-gray-100 rounded-lg bg-white shadow-md py-4"
          >
            <router-link
              v-for="item in materials"
              :to="item.link"
              :key="item.index"
              class="relative flex items-center space-x-3 px-4 cursor-pointer"
            >
              <div class="flex-shrink-0">
                <img
                  class="w-4 text-center"
                  src="@/assets/images/document.svg"
                />
              </div>
              <div class="min-w-0 flex-1">
                <p class="text-base text-primary-800 font-bold ">
                  {{ item.type
                  }}<span
                    v-if="item.special"
                    class="text-xs text-gray-800 font-normal italic ml-2"
                    >*{{ item.special }}</span
                  >
                </p>
                <p class="text-sm text-gray-800">{{ item.description }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Third - Gathering Information -->

      <div
        ref="gather"
        class="relative bottom-36 pt-20 md:flex items-start w-full timeline"
      >
        <div class="flex flex-col items-center w-1/2">
          <!-- Left Top -->
          <div class=" flex min-w-0 flex-col items-center w-full">
            <div class="flex justify-end left-6 w-full relative top-16">
              <span
                class="relative left-1 top-1 z-10 my-4  h-1 w-1/5 bg-gray-250"
              >
              </span>
              <span
                class="z-10   h-12 w-12 rounded-full border-8 border-primary-800 bg-white"
              >
              </span>
            </div>
            <div class=" flex min-w-0 flex-col w-full pb-32 pl-16">
              <div id="gather" class="text-lg font-bold mb-2">
                Gathering Information
              </div>
              <div class="text-xs text-primary-800 mb-2 font-bold">
                during open enrollment
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80 mb-4">
                Collect your employees' enrollment information via our
                convenient enrollment forms or your own methods.
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80 mb-4">
                Compile the form information into the appropriate enrollment
                data template provided below.
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80">
                If you are working with a carrier, ensure they follow our file
                naming conventions which can be found here.
              </div>
              <div
                class="flex flex-col items-center font-bold text-white mt-8 mr-4  text-xs"
              >
                <div class="w-3/4 flex flex-col items-end">
                  <div class="flex">
                    <Button
                      class="bg-primary-light py-2 px-4 rounded mb-2 mr-2"
                      href="/Enrollment Template.xlsx"
                      download
                    >
                      Benefit Enrollment Template
                    </Button>
                    <router-link
                      to="/support/articles/360043060233"
                      class="bg-gray-50 text-gray-800 py-2 px-2 rounded mb-2 hover:bg-gray-200"
                    >
                      How-to
                    </router-link>
                  </div>
                  <div class="flex">
                    <a
                      class="bg-primary-800 py-2 px-4 rounded mb-2 mr-2 cursor-pointer hover:bg-primary-900"
                      href="/Contribution%20Template.xlsx"
                      download
                    >
                      Contribution Template
                    </a>
                    <router-link
                      to="/support/articles/360042588034"
                      class="bg-gray-50 text-gray-800 py-2 px-2 rounded mb-2 hover:bg-gray-200"
                    >
                      How-to
                    </router-link>
                  </div>
                  <div class="flex">
                    <a
                      class="bg-primary-800 py-2 px-4 rounded mb-2 mr-2 cursor-pointer hover:bg-primary-900"
                      href="/COBRA Plan and Rate Information.xlsx"
                      download
                    >
                      COBRA Templates
                    </a>
                    <div
                      class="bg-white text-white py-2 px-2 rounded mb-2 cursor-default"
                    >
                      How-to
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" w-1/2 flex flex-col items-center py-4">
          <!-- Right top -->
          <img class=" w-3/4 " src="@/assets/images/gatherInfo.svg" />
          <div
            class="flex flex-col items-center font-bold text-white mt-8 mr-8 text-xs relative bottom-24 w-full"
          >
            <div class="flex">
              <Button
                class="bg-primary-light py-2 px-12 rounded mb-2 mr-2"
                href="/RMR Enrollment Form.pdf"
                download
              >
                Benefit Enrollment Form
              </Button>
            </div>
            <div class="flex">
              <router-link
                to="/forms"
                class="bg-primary-800 py-2 px-16 rounded mb-2 mr-2 hover:bg-primary-900"
              >
                Form Directory
              </router-link>
            </div>
            <div class="flex justify-center">
              <div
                class=" py-1 px-4 italic text-gray-800 text-center w-64 font-normal"
              >
                These forms are soley for the collection of information and
                cannot be used to submit data to us.
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Fourth - Upload Information -->
      <div
        ref="upload"
        class="flex relative bottom-36 items-start w-full timeline"
      >
        <div class="flex flex-col items-center w-1/2">
          <!-- Left Top -->
          <div class=" flex min-w-0 flex-col items-center w-full">
            <div class="flex justify-end left-6 w-full relative top-16">
              <span
                class="relative left-1 top-1 z-10 my-4  h-1 w-1/5 bg-gray-250"
              >
              </span>
              <span
                class="z-10   h-12 w-12 rounded-full border-8 border-primary-800 bg-white"
              >
              </span>
            </div>
            <div class="flex min-w-0 flex-col w-full pl-16">
              <div id="upload" class="text-lg font-bold mb-2">
                Upload Information
              </div>
              <div class="text-xs text-primary-800 mb-2 font-bold">
                one month prior to plan start
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80">
                Your account manager will provide you with a link where you can
                upload your templates of gathered information.
              </div>
              <div
                class="flex flex-col items-start justify-center my-8 text-xs"
              >
                <div
                  class="flex items-center border-primary-light border-2 py-1 px-2 rounded mb-2 flex"
                >
                  <img class="w-6 mr-2" src="@/assets/images/lock.svg" />
                  <div class="mr-2 font-bold">https://rmr.files.com/u/</div>
                  <div
                    class="bg-gray rounded px-2 py-1 text-gray-600 font-bold"
                  >
                    Your RMR code.
                  </div>
                </div>
              </div>
              <div class="text-sm text-gray-750 w-72 lg:w-80">
                On average it takes cards 3-4 weeks to arrive to your
                participants. To ensure they have access to their funds right
                away, please provide your data to us
                <strong>no later than 1 month prior</strong> to the start of
                your plan year.
              </div>
            </div>
            <span
              class="z-10 relative left-1/2 top-8  h-12 w-12 rounded-full border-8 border-primary-800 bg-primary-800"
            >
              <img class="w-12 mr-4" src="@/assets/images/check.svg" />
            </span>
          </div>
        </div>
        <div class=" w-1/2 flex flex-col items-center py-4">
          <!-- Right top -->
          <img class="w-3/4" src="@/assets/images/going_up.svg" />
        </div>
      </div>

      <!-- Fifth -->
      <div
        class="flex relative bottom-8 flex-col items-center justify-center w-full"
      >
        <img class="w-1/2" src="@/assets/images/rmrPower.svg" />
        <div class="text-center font-bold text-2xl mt-8 mb-4">
          We'll take it from here!
        </div>
      </div>

      <router-link
        v-for="item in steps"
        :to="item.link"
        :key="item.index"
        class="relative bottom-8 flex flex-col items-center justify-center w-full cursor-pointer"
      >
        <div
          class="flex justify-center bg-gray-75 hover:shadow-md rounded px-8 py-2 mb-2 w-80"
        >
          <img class="w-6 mr-4" src="@/assets/images/check.svg" />
          <div class="text-center text-base font-bold">{{ item.step }}</div>
        </div>
      </router-link>
      <div class="text-center mb-8 text-xl font-bold text-primary-800">
        Have questions? Need to make changes?
      </div>
      <div class="hidden md:flex w-full justify-evenly text-base">
        <Button
          reg
          large-icons
          slide-text-right="888.722.1223"
          trailing="flaticon-phone"
          href="tel:8887221223"
          class="mb-4 w-72 md:w-52"
        >
          Give Us A Call
        </Button>

        <Button
          reg
          large-icons
          trailing="flaticon-email"
          slide-text-right="info@rmrbenefits.com"
          href="mailto:info@rmrbenefits.com"
          tooltip-click="Email Copied!"
          @click-button="setClipboard('info@rmrbenefits.com')"
          class="mb-4 w-72 md:w-52"
        >
          Send Us An Email
        </Button>
        <Button
          reg
          large-icons
          trailing="flaticon-question"
          onclick="zE('messenger', 'open')"
          class="mb-4 w-72 md:w-52"
        >
          Chat Live With Us
        </Button>
      </div>
    </div>
    <!-- Mobile -->
    <div>
      <div class="md:hidden flex flex-col justify-center items-center">
        <!-- Right top -->
        <div
          class="flex min-w-0 flex-col bg-primary-800 p-4 w-full sm:w-3/4 rounded md:w-64 lg:w-80 w-96"
        >
          <div class="text-xl font-bold text-white mb-2">Open Enrollment</div>
          <div class="text-sm text-white">
            A smooth and easy open enrollment starts with your employees
            understanding their options and you understanding the steps in the
            process timeline. It is in your best interest to dedicate adequate
            time to complete each step to its fullest.
          </div>
        </div>
        <img class="w-10" src="@/assets/images/mobileTimeline.svg" />
        <div class="text-sm font-bold text-primary-800 my-4">
          months leading up to open enrollment
        </div>
        <div class="text-2xl font-bold text-gray-800 my-4">
          Employee Education
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          Providing employees resources to help them understand benefits offered
          can greatly increase participation rates.
        </div>
        <img class="w-72" src="@/assets/images/employeeEducation.svg" />
        <!-- Materials -->
        <div class="flex min-w-0 flex-col w-full sm:w-3/4">
          <div class="flex justify-start">
            <div
              class="text-sm font-bold py-2 px-4 text-gray-800 bg-gray-0 rounded"
            >
              Materials
            </div>
          </div>
          <div class=" w-full flex justify-center ">
            <div
              class="grid grid-cols-1  w-full  border-t-2 border-gray-0 bg-white"
            >
              <router-link
                v-for="item in materials"
                :key="item.index"
                :to="item.link"
                class="md:px-8  flex items-center justify-evenly border-b-2 border-gray-0 py-3"
              >
                <div class="min-w-0 flex-1 ">
                  <div href="#" class="focus:outline-none">
                    <p class="text-sm font-bold text-primary-800 font-bold">
                      {{ item.type }}<span v-if="item.special">*</span>
                    </p>
                    <p class="text-sm text-gray-800">
                      {{ item.description }}
                      <span v-if="item.special" class="italic"
                        >*{{ item.special }}</span
                      >
                    </p>
                  </div>
                </div>
                <div class="flex-shrink-0">
                  <div
                    class="w-4 text-center text-gray-0 flaticon-right-arrow"
                  />
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <img class="w-10" src="@/assets/images/mobileTimeline.svg" />
        <div class="text-sm font-bold text-primary-800 my-4">
          during open enrollment
        </div>
        <div class="text-2xl font-bold text-gray-800 my-4">
          Gathering Information
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          Collect your employees' enrollment information via our convenient
          enrollment forms or your own methods.
        </div>
        <img class="w-72 relative top-8" src="@/assets/images/gatherInfo.svg" />
        <Button
          class="bg-primary-light my-6 py-2 px-6 text-sm rounded mb-2 font-bold text-white relative bottom-12"
          href="/RMR Enrollment Form.pdf"
          download
        >
          Benefit Enrollment Form
        </Button>
        <router-link
          to="/forms"
          class="bg-primary-800 py-2 px-10 rounded text-sm font-bold text-white relative bottom-12"
        >
          Form Directory
        </router-link>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          While we provide these forms as a convenient way to collect your
          participant information, they are not the format in which we accept
          your data.
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          Compile the form information into the appropriate enrollment data
          template provided below.
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-2">
          If you are working with a carrier, ensure they follow our file naming
          conventions which can be found here.
        </div>
        <div
          class="flex flex-col items-center font-bold text-white mt-4 text-sm"
        >
          <div class="flex">
            <Button
              class="bg-primary-light py-2 px-4 rounded mb-2 mr-2"
              href="/Enrollment Template.xlsx"
              download
            >
              Benefit Enrollment Template
            </Button>
            <router-link
              to="/support/articles/360043060233"
              class="bg-gray-50 text-gray-800 py-2 px-2 rounded mb-2"
            >
              How to
            </router-link>
          </div>
          <div class="flex">
            <a
              class="bg-primary-800 py-2 px-4 rounded mr-2 mb-2"
              href="/Contribution%20Template.xlsx"
              download
            >
              Contribution Template
            </a>
            <router-link
              to="/support/articles/360042588034"
              class="bg-gray-50 text-gray-800 py-2 px-2 rounded mb-2"
              >How to</router-link
            >
          </div>
          <div class="flex">
            <a
              class="bg-primary-800 py-2 px-4 rounded mr-2"
              href="/COBRA Plan and Rate Information.xlsx"
              download
            >
              COBRA Templates
            </a>
          </div>
        </div>
        <img class="w-10" src="@/assets/images/mobileTimeline.svg" />
        <div class="text-2xl font-bold text-gray-800 my-4">
          Upload Information
        </div>
        <div class="text-sm font-bold text-primary-800 my-4">
          1 month prior to plan start
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          Your account manager will provide you with a link where you can
          securely upload your templates of gathered information.
        </div>
        <div class="flex flex-col items-start justify-center my-8 text-xs">
          <img class="w-72" src="@/assets/images/going_up.svg" />
          <div
            class="flex items-center border-gray-200 border-2 py-1 px-2 rounded mb-2 flex"
          >
            <img class="w-6 mr-2" src="@/assets/images/lock.svg" />
            <div class="mr-2 font-bold">https://rmr.files.com/u/</div>
            <div class="bg-gray-200 rounded px-2 py-1 text-gray-600 font-bold">
              Your RMR code
            </div>
          </div>
        </div>
        <div class="text-sm text-gray-800 w-full sm:w-1/2 text-center mb-4">
          On average it takes cards 3-4 weeks to arrive to your participants. To
          ensure they have access to their funds right away, please provide your
          data to us <strong>no later than 1 month prior</strong> to the start
          of your plan year.
        </div>

        <img class="w-10" src="@/assets/images/mobileTimeline.svg" />
        <img class="w-10 relative bottom-10" src="@/assets/images/check.svg" />
        <!-- <div class="flex flex-col items-center justify-center w-full">
                    <div
                    class="z-10 h-14 w-2 bg-primary-800"
                    >
                    </div>
                    <img class="w-8 relative bottom-1" src="@/assets/images/check.svg"/>
                </div> -->
        <div class="font-bold text-lg mb-8">We'll take it from here!</div>
        <img class="w-72" src="@/assets/images/rmrPower.svg" />
        <div class=" w-full flex justify-center ">
          <div
            class="grid grid-cols-1 w-full  border-t-2 border-gray-0 bg-white"
          >
            <router-link
              v-for="item in steps"
              :to="item.link"
              :key="item.index"
              class="px-8  flex items-center justify-evenly border-b-2 border-gray-0 py-2"
            >
              <img class="w-4 mr-4" src="@/assets/images/check.svg" />
              <div class="min-w-0 flex-1 ">
                <div href="#" class="focus:outline-none">
                  <p class="text-sm font-bold text-primary-800 font-bold">
                    {{ item.step }}
                  </p>
                </div>
              </div>
              <div class="flex-shrink-0">
                <div class="w-4 text-center text-gray-0 flaticon-right-arrow" />
              </div>
            </router-link>
          </div>
        </div>
        <div class="font-bold text-lg mt-12">Have questions?</div>
        <div class="font-bold text-lg mb-8">Need to make a change?</div>
        <div class="relative flex flex-col items-center md:w-60 ">
          <Button
            reg
            large-icons
            slide-text-right="888.722.1223"
            trailing="flaticon-phone"
            href="tel:8887221223"
            class="mb-4 w-72 md:w-52"
          >
            Give Us A Call
          </Button>

          <Button
            reg
            large-icons
            trailing="flaticon-email"
            slide-text-right="info@rmrbenefits.com"
            href="mailto:info@rmrbenefits.com"
            tooltip-click="Email Copied!"
            @click-button="setClipboard('info@rmrbenefits.com')"
            class="mb-4 w-72 md:w-52"
          >
            Send Us An Email
          </Button>
          <Button
            reg
            large-icons
            trailing="flaticon-question"
            onclick="zE('messenger', 'open')"
            class="mb-4 w-72 md:w-52"
          >
            Chat Live With Us
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@/components/ui/Button.vue";

export default {
  components: {
    Button
  },
  data() {
    return {
      componentName: "Open Enrollment",
      materials: [
        {
          type: "Flexible Spending Account",
          description: "Eligible medical, dental, and dependent care expenses.",
          index: 0,
          link: "/services/fsa"
        },
        {
          type: "Commuter Benefits",
          description: "Eligible transit or parking expenses to and from work.",
          index: 1,
          link: "/services/transit"
        },
        {
          type: "Limited Purpose Flex Spending Account",
          description: "Exclusively eligible dental and vision expenses.",
          index: 2,
          link: "/support/articles/19197575450643"
        },
        {
          type: "Tuition Reimbursement",
          description: "Qualified education coverage.",
          index: 3,
          link: "/services/tuition"
        },
        {
          type: "Dependent Care Account",
          description: "Eligible childcare and dependent care expenses.",
          index: 4,
          link: "/services/dca"
        },
        {
          type: "Health Reimbursement Arrangement",
          description: "Employer specified and funded healthcare coverage.",
          index: 5,
          link: "/services/hra"
        },
        {
          type: "Health Savings Account",
          description: "All qualified medical expenses.",
          index: 6,
          link: "/services/hsa"
        },
        {
          type: "Lifestyle and Wellness Account",
          description: "Employer specific wellness benefit.",
          index: 7,
          link: "/services/wellness"
        },
        {
          type: "COBRA",
          description:
            "Consolidated Omnibus Budget Reconciliation Act allows individuals who have lost their job-based health insurance to continue their coverage for a limited time by paying the full premium themselves.",
          special: "Required by law",
          index: 8,
          link: "/support/sections/360003792274"
        }
      ],
      steps: [
        {
          step: "Accounts Created",
          index: 0,
          link: "/support/articles/18890494920723"
        },
        {
          step: "Cards Sent",
          index: 1,
          link: "/support/articles/18173181891731"
        },
        {
          step: "Mobile App",
          index: 2,
          link: "/support/articles/360045368453"
        },
        { step: "Online Portal", index: 3, link: "/login" },
        { step: "Live Person Support", index: 4, link: "/contact" }
      ],
      timeline: [
        {
          name: "Open Enrollment",
          href: "#enrollment",
          status: "complete"
        },
        {
          name: "Employee Education",
          href: "#education",
          status: "complete"
        },
        {
          name: "Gathering Information",
          href: "#gather",
          status: "current"
        },
        {
          name: "Upload Information",
          href: "#upload",
          status: "upcoming"
        }
      ],
      current: "",
      order: [
        "Open Enrollment",
        "Employee Education",
        "Gathering Information",
        "Upload Information"
      ],
      positionEducation: 0,
      positionGather: 0,
      positionUpload: 0,
      windowTop: 0
    };
  },
  mounted() {
    this.$zendesk.load("a95486a9-0bcf-4bda-99ac-f81566ab1958");
    window.addEventListener("scroll", this.onScroll);
    // console.dir(this.$refs.upload)
    this.positionEducation = this.$refs.education.offsetTop - 50;
    this.positionGather = this.$refs.gather.offsetTop;
    this.positionUpload = this.$refs.upload.offsetTop;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    setClipboard(value) {
      var tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
    },
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    }
  },
  watch: {
    windowTop() {
      if (this.windowTop > this.positionUpload) {
        this.current = "Upload Information";
      } else if (this.windowTop > this.positionGather) {
        this.current = "Gathering Information";
      } else if (this.windowTop > this.positionEducation) {
        this.current = "Employee Education";
      } else if (this.windowTop < this.positionEducation) {
        this.current = "";
      }
    }
  }
};
</script>
<style>
.timeline {
  background-image: url("../../../assets/images/timeline.svg");
  background-repeat: repeat-y;
  background-position: center;
  background-size: auto;
}

/* .gather-info{
        width: 600px;
    } */
</style>
